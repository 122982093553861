<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="/pta_logo.jpg" alt="PTA Logo" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Or
          {{ ' ' }}
          <router-link to="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
            login
          </router-link>
        </p>
      </div>
      <form @submit.prevent="submit" class="mt-8 space-y-6">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input v-model="email" id="email-address" name="email" autocomplete="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
          </div>

          <div>
            <label for="first_name" class="sr-only">First name</label>
            <input v-model="first_name" id="first_name" name="first_name" type="first_name" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="First name" />
          </div>

          <div>
            <label for="last_name" class="sr-only">Last name</label>
            <input v-model="last_name" id="last_name" name="last_name" type="last_name" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Last name" />
          </div>

          <Datepicker
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              v-model="birthday"
              :enableTimePicker="false"
              placeholder="Birthday"
              :format="format"
          />

          <div>
            <label for="birth_place" class="sr-only">Birth place</label>
            <input v-model="birth_place" id="birth_place"  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Birth place" />
          </div>


            <!--            <input v-model="repeat_password" type="password" id="repeat-password" name="repeat_password" autocomplete="current-password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Repeat password" />-->



          <div>
            <label for="phone" class="sr-only">Phone</label>
            <input v-model="phone" id="phone" name="phone" type="phone" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Phone" />
          </div>

          <div>
            <label for="password" class="sr-only">Password</label>
            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
          </div>


          <div>
            <label for="password" class="sr-only">Repeat assword</label>
            <input v-model="repeat_password" type="password" id="repeat-password" name="repeat_password" autocomplete="current-password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Repeat password" />
          </div>
        </div>

        <div class="flex items-center h-5">
          <input v-model="corporate_account" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
          <p class="ml-4 text-gray-500">Corporate account</p>
        </div>

        <div class="flex items-center justify-between">
          <div class="text-sm">
            <router-link to="/forgot-password" class="font-medium text-indigo-600 hover:text-indigo-500">
              Forgot your password?
            </router-link>
          </div>
        </div>

        <div>
          <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
            </span>
            Register
          </button>
        </div>
        <error-label v-if="errors.length" :errors="errors"></error-label>
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { LockClosedIcon } from '@heroicons/vue/solid'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import errorLabel from '../components/errorLabel'
import {mapState, mapActions} from 'vuex'


export default {
  name: 'Login',
  setup () {
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }

    return { v$: useVuelidate(), format: format }
  },
  computed: {
    ...mapState('auth', ['user']),
    errors() {
      let output = [];
      this.v$.$errors.forEach(e=>{
        output.push(e.$message)
      })
      return output;
    }
  },
  mounted() {
    console.log(this.$store.state.auth)
  },
  data() {
    return {
      email: null,
      password: null,
      repeat_password: null,
      first_name: null,
      last_name: null,
      phone: null,
      birthday: null,
      birth_place: null,
      corporate_account: false
    }
  },
  components: {
    LockClosedIcon,
    errorLabel
  },
  validations() {
    return {
      email: {
        required: {
          $validator: required,
          $message: 'Email is required'
        },
        email: {
          $validator: email,
          $message: 'First name is not valid'
        },
      },
      first_name: {
        required: {
          $validator: required,
          $message: 'Last name is required'
        }
      },
      birth_place: {
        required: {
          $validator: required,
          $message: 'Birth place is required'
        }
      },
      last_name: {
        required: {
          $validator: required,
          $message: 'Email is required'
        }
      },
      password: {
        required: {
          $validator: required,
          $message: 'Password is required'
        },
        minLength: {
          $validator: minLength(8),
          $message: 'Your password must be at least 8 characters'
        }
      },
      phone: {
        required: {
          $validator: required,
          $message: 'Phone is required'
        },
        minLength: {
          $validator: minLength(8),
          $message: 'Your phone must be at least 8 characters'
        }
      },
      repeat_password: {
        required: {
          $validator: required,
          $message: 'Repeat password is required'
        },
        minLength: {
          $validator: minLength(8),
          $message: 'Repeat password must be at least 8 characters'
        },
        sameAs: {
          $validator: sameAs('password'),
          $message: 'Please retype your password correct'
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      console.log("oamsdoakmsd")
      this.v$.$touch()
      if(!this.v$.$invalid) {
        let res = await this.axios.post('/user/register/',
            {email: this.email, password: this.password, first_name: this.first_name, last_name: this.last_name,
            birth_place: this.birth_place, birthday: this.format(this.birthday), corporate_account: this.corporate_account})
        await this.$router.push('/login')
      }
    }
  }
}
</script>